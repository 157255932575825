import React from 'react';
import styles from './index.module.scss';
import { FeatureContentIcons } from '~/common/components';
import { Heading } from '../header-banner/components/heading';
import { HeaderBannerMediaContent } from '../header-banner/components/media-content';
import { FeatureIcon, MediaContent } from '~/common/models';
import cn from 'classnames';

interface Social {
  image: string;
  link: string;
}

interface HeaderArticleProp {
  contentId: number;
  articleTitle: string;
  articleAuthor: string;
  articleDate: string;
  articleTime: string;
  mediaContents: MediaContent[];
  featureContents?: FeatureIcon[];
  legalDisclaimer: string;
  socials: Social[];
  legalDisclaimerFontColor: string;
}

export default (props: HeaderArticleProp) => {
  const [headingContent, setHeadingContent] = React.useState<
    FeatureIcon | undefined
  >(undefined);

  React.useEffect(() => {
    if (!!props.featureContents) {
      setHeadingContent(
        props.featureContents.find((f) => f.contentType === 'Heading')
      );
    }
  }, []);

  return (
    <div id="header-article" className={cn('container', styles.headerArticle)}>
      <div
        className={styles.articleTitle}
        dangerouslySetInnerHTML={{ __html: props.articleTitle }}
      />
      <div className={styles.twoColumn}>
        <div className={styles.articleUser}>
          <div>{props.articleAuthor}</div>
          <div>
            <span>{props.articleDate}</span>
            <span>|</span>
            <span>{props.articleTime}</span>
          </div>
        </div>
        <div className={styles.articleSocial}>
          <div className={styles.shareText}>Share</div>
          {props.socials &&
            props.socials.map((social, index) => (
              <div key={index}>
                <a
                  href={social.link}
                  className={styles.socialLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={social.image} alt={social.link} />
                </a>
              </div>
            ))}
        </div>
      </div>

      <div className={styles.mediaWrapper}>
        <div className={styles.articleMedia}>
          <HeaderBannerMediaContent
            legalDisclaimerPosition="right"
            legalDisclaimer={props.legalDisclaimer}
            legalDisclaimerColor={props.legalDisclaimerFontColor}
            mediaContents={props.mediaContents}
          />

          <FeatureContentIcons features={props.featureContents || []} />
        </div>

        {headingContent && (
          <Heading
            containerClassName={styles.headingContainer}
            heading={headingContent}
          />
        )}
      </div>
    </div>
  );
};
