import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import ReactPlayer from 'react-player';
import { SvgIcon } from '@ui-elements';
import { Link, MediaContent } from '~/common/models';
import styles from './index.module.scss';

interface HeaderBannerMediaContentProp {
  legalDisclaimer?: string;
  legalDisclaimerColor?: string;
  legalDisclaimerPosition?: 'auto' | 'right' | 'left' | 'center';
  mediaContents?: MediaContent[];
  isFullHeightMobile?: boolean;
  bannerHyperlink?: Link;
}
export const HeaderBannerMediaContent =
  React.memo<HeaderBannerMediaContentProp>(
    ({
      legalDisclaimer,
      mediaContents,
      isFullHeightMobile,
      legalDisclaimerColor,
      legalDisclaimerPosition,
      bannerHyperlink,
    }) => {
      const videoRef = useRef<ReactPlayer>(null);
      const [isPlayVideo, setPlayVideo] = useState<boolean>(false);
      const [isVideoEnded, setVideoEnded] = useState<boolean>(false);

      const [images, setImages] = useState<MediaContent[]>([]);
      const [videoContent, setVideoContent] = useState<
        MediaContent | undefined
      >(undefined);

      useEffect(() => {
        if (withVideo()) {
          setVideoContent(getVideo());
        }
      }, []);

      useEffect(() => setImages(getImages() || []), [videoContent]);

      const handleEnded = () => {
        setVideoEnded(true);
        setTimeout(() => {
          setPlayVideo(false);
          if (!!videoRef.current) {
            videoRef.current.seekTo(0);
          }
        }, 1000);
      };

      const withVideo = () => {
        return mediaContents?.some((mc) => mc.mediaType === 'Video');
      };

      const getVideo = () => {
        return mediaContents?.find((mc) => mc.mediaType === 'Video');
      };

      const getImages = () => {
        const imageContent = mediaContents?.filter(
          (mc) => mc.mediaType === 'Image'
        );
        if (!!videoContent && imageContent?.length === 0) {
          if (!!videoContent.thumbnail) {
            imageContent?.push({
              mediaSource: videoContent.thumbnail,
              mediaType: 'Image',
              screenSizes: [],
            });
          }
        }
        return imageContent;
      };

      const getClassName = (sizes: string[]): string => {
        return cn(styles.bannerImage, {
          [styles.sizeAll]: sizes.length === 0,
          [styles.sizeXS]: sizes.includes('XS'),
          [styles.sizeSM]: sizes.includes('SM'),
          [styles.sizeLG]: sizes.includes('LG'),
          [styles.sizeXL]: sizes.includes('XL'),
        });
      };

      const onBannerClick = React.useCallback(() => {
        if (!!bannerHyperlink) {
          if (bannerHyperlink.target === '_blank') {
            window.open(bannerHyperlink.url, '_blank');
          } else {
            window.location.href = bannerHyperlink.url;
          }
        }
      }, [bannerHyperlink]);

      return (
        <div
          className={cn(styles.headerMediaContent, {
            [styles.withVideo]: withVideo(),
            [styles.fullHeightMobile]: isFullHeightMobile,
            [styles.bannerClickable]: !!bannerHyperlink,
          })}
          onClick={onBannerClick}
        >
          {videoContent && (
            <>
              {(isPlayVideo || images.length === 0) && (
                <div
                  className={cn(styles.videoContentWrapper, {
                    [styles.noThumbnail]: images.length === 0,
                    [styles.fadeIn]: isPlayVideo && !isVideoEnded,
                    [styles.fadeOut]: isVideoEnded && images.length !== 0,
                  })}
                >
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    pip={false}
                    ref={videoRef}
                    className={styles.videoContent}
                    url={videoContent?.mediaSource}
                    onEnded={handleEnded}
                    playing={isPlayVideo}
                    controls
                    config={{
                      youtube: {
                        playerVars: {
                          showinfo: 0,
                          disablekb: 1,
                          autoplay: images.length > 1 ? 1 : 0,
                          controls: 1,
                          modestbranding: 1,
                        },
                      },
                    }}
                  />
                </div>
              )}

              {!isPlayVideo && (
                <div className={styles.playButtonWrapper}>
                  <SvgIcon
                    size={4}
                    type="play"
                    color="#ffffff"
                    fill="#ffffff"
                    className={styles.playButtonTrigger}
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      setVideoEnded(false);
                      setPlayVideo(true);
                    }}
                  />
                </div>
              )}
            </>
          )}

          {!isPlayVideo && (
            <div className={styles.imageContainer}>
              {getImages()?.map((img, index) => (
                <img
                  key={index}
                  src={img.mediaSource}
                  alt={img.mediaSource}
                  className={getClassName(img.screenSizes || [])}
                />
              ))}
            </div>
          )}

          {legalDisclaimer && (
            <div
              className={cn(
                styles.legalDisclaimer,
                styles[`${legalDisclaimerPosition}Disclaimer`]
              )}
            >
              <p
                data-field="legalDisclaimer"
                style={{ color: legalDisclaimerColor }}
              >
                <span>{legalDisclaimer}</span>
              </p>
            </div>
          )}
        </div>
      );
    }
  );
